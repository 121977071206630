/* Generated by Font Squirrel (https://www.fontsquirrel.com) on May 19, 2016 */



@font-face {
    font-family: 'ikaros_sanslight';
    src: url('ikaros-light-webfont.woff2') format('woff2'),
         url('ikaros-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ikaros_sansregular';
    src: url('ikaros-regular-webfont.woff2') format('woff2'),
         url('ikaros-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}