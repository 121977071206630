/* MNHN */
.label table {
  margin-bottom: 40px; }

.label ul {
  width: 50%; }

div#content > section {
  float: left;
  position: relative; }

#section-1 {
  height: 350px;
  width: 40%; }

#section-2 {
  height: 350px;
  width: 40%; }

#section-3 {
  height: 350px;
  width: 20%; }

#section-4 {
  height: 600px;
  width: 60%; }

#section-5 {
  height: 600px;
  width: 40%; }

#section-6 {
  height: 350px;
  width: 100%; }

#section-7 {
  height: 350px;
  width: 40%; }

#section-8 {
  height: 350px;
  width: 40%; }

#section-9 {
  height: 350px;
  width: 20%; }

footer {
  clear: both;
  height: 332px;
  width: 100%; }
  @media screen and (max-width: 1000px) {
    footer {
      min-height: 500px; } }

@media screen and (max-width: 1000px) {
  #section-1, #section-2, #section-3, #section-4, #section-5, #section-6, #section-7, #section-8, #section-9 {
    width: 100%; } }

.recolnat-menu {
  border: none;
  border-bottom: 1px solid black;
  display: block;
  height: 60px;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 50; }
  @media screen and (max-width: 1000px) {
    .recolnat-menu {
      height: 291px;
      position: relative; } }

#content {
  padding-top: 60px; }

.link, ul.link-list a, section.objectifs a, footer > section ul a, .page ul a, .chiffres h2 a, .chiffres table thead td:not(:first-child) a, .chiffres table tbody td a, .link-hover, ul.link-list a:hover, section.objectifs a:hover, footer > section ul a:hover, .page ul a:hover, .chiffres table tbody td a:hover, .link-inverse, .link-inverse-hover {
  display: inline-block;
  font-family: 'Merriweather', serif;
  font-size: 14px;
  padding: 0 0.5em;
  text-decoration: none;
  transition: background-color 0.5s ease, color 0.5s ease;
  vertical-align: text-top;
  white-space: pre-wrap; }

.link, ul.link-list a, section.objectifs a, footer > section ul a, .page ul a, .chiffres h2 a, .chiffres table thead td:not(:first-child) a, .chiffres table tbody td a {
  color: #DD6754; }

.link-hover, ul.link-list a:hover, section.objectifs a:hover, footer > section ul a:hover, .page ul a:hover, .chiffres table tbody td a:hover {
  background-color: #DD6754;
  color: white; }

.link-inverse {
  color: white; }

.link-inverse-hover {
  background-color: white;
  color: #DD6754; }

.url, .page .post a {
  color: #DD6754;
  text-decoration: none; }

.url-hover, .page .post a:hover {
  text-decoration: underline; }

* {
  cursor: default; }

html {
  background-color: #FFFCF7; }

table {
  border-collapse: collapse; }
  table th, table td {
    border: 1px solid black; }
  table th {
    background-color: black;
    color: white; }
  table td {
    text-align: center; }
  table * {
    font-family: 'Merriweather', serif;
    vertical-align: middle; }

.botanic-insert {
  background-color: #fffcf7;
  background-color: rgba(255, 252, 247, 0.9);
  margin-left: 10px;
  height: 43%;
  width: 55%; }
  @media screen and (max-width: 400px) {
    .botanic-insert {
      width: 75%; } }

ul.link-list {
  list-style-image: url("../img/bullet-orange.png");
  margin: 0;
  padding: 0; }
  ul.link-list li {
    list-style-position: outside;
    margin: 0 5px 5px 5px;
    padding: 0; }

.spotlight {
  font-size: 24px;
  line-height: 1em; }
  @media screen and (max-width: 400px) {
    .spotlight {
      font-size: 21px; } }

.spotlight-subtitle {
  font-size: 14px;
  text-transform: lowercase; }

h1 {
  font-family: 'ikaros_sansregular', sans-serif;
  padding: 1em 0;
  text-transform: uppercase; }

h2, h3, h4, p, ul {
  margin-top: 0px;
  margin-bottom: 0px; }

h2, h3, h4, .explore-content {
  font-family: 'ikaros_sansregular';
  letter-spacing: 1pt;
  text-transform: uppercase;
  font-weight: bold; }

h2 {
  font-size: 19px;
  line-height: 28.5px;
  padding-top: 20px;
  padding-bottom: 20px; }
  @media screen and (max-width: 400px) {
    h2 {
      font-size: 16px; } }

h3, h4 {
  font-size: 16px;
  line-height: 24px;
  padding-top: 20px;
  padding-bottom: 20px; }
  @media screen and (max-width: 400px) {
    h3, h4 {
      font-size: 13px;
      line-height: 24px; } }

p {
  font-family: "ikaros_sansregular";
  letter-spacing: 0.5pt;
  line-height: 1.5em; }

.emphasis {
  font-style: italic; }

.contains-v-centered-elements, section.colhelper, section.colhelper a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.v-centered-elements, .botanic-insert, section.colhelper a, section.colhelper a h2 {
  vertical-align: middle; }

#flags {
  position: absolute;
  right: 0; }
  #flags .flag {
    border: none;
    float: left;
    height: 24px;
    opacity: 0.6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 0.5s ease;
    width: 40px; }
    #flags .flag:hover {
      opacity: 1; }
  #flags .flag.fr {
    background-image: url("https://explore.recolnat.org/resources/img/header/fr.png"); }
  #flags .flag.en {
    background-image: url("https://explore.recolnat.org/resources/img/header/en.png"); }

@media screen and (max-width: 1000px) {
  #content {
    padding-top: 0; } }

#content > section h2 {
  padding: 40px 20px 20px 30px; }

#content > section .link-list {
  margin-left: 50px;
  margin-right: 30px;
  margin-top: 10px; }

@media screen and (max-width: 1000px) {
  .row {
    display: block; } }

@media screen and (max-width: 1000px) {
  #lastrow {
    width: 100%; } }

section.objectifs {
  background-color: #120E15;
  color: #FFFCF7;
  text-overflow: auto; }
  section.objectifs p.summary {
    padding-left: 30px;
    padding-right: 30px; }
  section.objectifs .stats {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 30px 20px 30px; }
    section.objectifs .stats p {
      padding-right: 30px; }
  section.objectifs .summary {
    font-size: 14px; }
    @media screen and (max-width: 400px) {
      section.objectifs .summary {
        font-size: 11px; } }

section.explorer-les-collections ul.rslides > li > .explore-content {
  background-color: rgba(71, 132, 136, 0.75);
  bottom: 0;
  color: #FFFCF7;
  font-size: 16px;
  left: 0;
  line-height: 120%;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  width: 30%; }
  @media screen and (max-width: 400px) {
    section.explorer-les-collections ul.rslides > li > .explore-content {
      font-size: 11px;
      width: 50%; } }

section.actualites {
  background-color: #A8EFC5;
  color: #191919; }
  section.actualites h2 {
    padding: 40px 20px 0 20px !important; }
  section.actualites ul.news-list {
    list-style-type: none;
    margin: 0 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0; }
    section.actualites ul.news-list a {
      color: black;
      display: block;
      line-height: 1;
      padding: 0;
      text-decoration: none; }
    section.actualites ul.news-list li.news-post {
      border-left: 3px solid rgba(0, 0, 0, 0.1) !important;
      line-height: 1;
      margin-bottom: 5px;
      padding: 3px 3px 3px 6px;
      transition: background-color 0.5s ease; }
      section.actualites ul.news-list li.news-post:hover {
        background-color: rgba(0, 0, 0, 0.1); }
      section.actualites ul.news-list li.news-post .post-date {
        font-family: 'ikaros_sansregular', sans-serif;
        font-size: 14px;
        font-weight: bold;
        padding-top: 0px; }
      section.actualites ul.news-list li.news-post .post-title {
        display: block;
        font-family: 'iakros_sansregular', serif;
        font-size: 14px;
        max-height: 2em;
        line-height: 1em;
        margin-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        width: 100%; }

section.contribuer {
  color: #191919;
  background-image: url("../../../../assets/travailler-sur-les-planches.jpg");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  section.contribuer h2 {
    padding-bottom: 0 !important;
    padding-top: 60px !important; }

section.labo {
  color: #191919;
  background-image: url("../../../../assets/entrer-dans-laboratoire.jpg");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  section.labo h2 {
    padding-bottom: 0 !important;
    padding-top: 60px !important; }

section.membres-et-partenaires {
  background-color: #FFFCF7;
  color: #191919;
  position: relative; }
  section.membres-et-partenaires h2 {
    padding-top: 60px !important; }
  section.membres-et-partenaires div.pw {
    display: -ms-flexbox;
    display: flex;
    width: 100%; }
    section.membres-et-partenaires div.pw div.logos {
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      section.membres-et-partenaires div.pw div.logos img {
        max-height: 70px;
        max-width: 105px;
        padding: 10px; }
        @media screen and (max-width: 1000px) {
          section.membres-et-partenaires div.pw div.logos img {
            max-height: 50px;
            max-width: 75px;
            padding: 5px; } }
        @media screen and (max-width: 550px) {
          section.membres-et-partenaires div.pw div.logos img {
            max-height: 30px;
            max-width: 45px;
            padding: 5px; } }
        @media screen and (max-width: 400px) {
          section.membres-et-partenaires div.pw div.logos img {
            display: none; } }
  @media screen and (max-width: 1000px) {
    section.membres-et-partenaires div {
      padding-top: 0px;
      padding-bottom: 10px; } }

section.carte {
  color: #191919;
  background-image: url("../../../../assets/carte-coel-modif.jpg");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  section.carte .botanic-insert {
    width: 60%;
    height: 80%; }

section.colhelper {
  text-align: center;
  background-image: url("../../../../assets/demander-specimen.jpg");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  section.colhelper a {
    width: 40%;
    height: 30%;
    margin-left: 30%;
    text-align: center;
    color: #FFFCF7;
    text-decoration: none;
    border: 1px solid #FFFCF7;
    background-color: rgba(25, 25, 25, 0.75); }
    section.colhelper a h2 {
      text-align: center;
      padding: 0;
      margin: auto; }
    @media screen and (max-width: 400px) {
      section.colhelper a {
        margin-left: 25%; } }

section.publications_presse {
  background-color: #DD6754; }
  section.publications_presse h3 {
    margin: 0;
    padding: 0; }
  section.publications_presse > a {
    background-color: #DD6754;
    border: 1px solid white;
    color: white;
    display: block;
    margin: 2em 1em;
    padding: 2em 1em 1em 1em;
    text-align: right;
    text-decoration: none;
    transition: color 0.5s ease, background-color 0.5s ease; }
    section.publications_presse > a:hover {
      background-color: white;
      border: 1px solid white;
      color: #DD6754; }

footer > section {
  float: left;
  height: 100%;
  width: 20%; }
  @media screen and (max-width: 1000px) {
    footer > section {
      clear: both;
      height: auto;
      padding-top: 3em;
      width: 100%; } }
  footer > section h2, footer > section h3, footer > section h4 {
    font-size: 14px;
    word-break: break-all; }
  footer > section h3 {
    margin: 0;
    padding: 1em; }
  footer > section > a, footer > section > h3 {
    margin: 5px; }
  footer > section p, footer > section a {
    font-size: 14px; }
    @media screen and (max-width: 400px) {
      footer > section p, footer > section a {
        font-size: 11px; } }
  footer > section ul {
    list-style-image: url("../img/bullet-orange.png"); }
  footer > section > a {
    border: 1px solid black;
    color: black;
    display: block;
    font-family: 'ikaros_sansregular';
    text-decoration: none;
    transition: background-color 0.5s ease, color 0.5s ease; }
    footer > section > a:visited {
      color: black; }
    footer > section > a > h3 {
      line-height: 1.1; }
    footer > section > a:hover {
      background-color: black;
      color: white; }
      footer > section > a:hover * {
        color: white; }

.entete {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%; }

.page {
  margin-bottom: 200px;
  text-align: justify; }
  .page > * {
    margin-left: auto;
    margin-right: auto;
    width: 80%; }
  .page > a.logo {
    float: right;
    margin: 0;
    width: auto; }
    .page > a.logo > img {
      margin: 50px;
      max-width: 150px; }
  .page main > p > a:not(.logo) {
    color: #DD6754;
    text-decoration: none; }
    .page main > p > a:not(.logo):hover {
      text-decoration: underline; }
  .page .post {
    border: 2px solid gray;
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 20px; }
  .page p {
    margin-bottom: 20px; }
  .page ul {
    padding-bottom: 20px;
    font-size: 16px;
    font-family: 'ikaros_sansregular'; }
  .page .chapeau {
    background-color: rgba(0, 0, 0, 0.05);
    font-style: italic;
    margin-bottom: 5em;
    padding: 1em; }
    .page .chapeau > a {
      color: #DD6754;
      text-decoration: none; }
      .page .chapeau > a:hover {
        text-decoration: underline; }
  .page ul.pagination {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
    height: 50px; }
    .page ul.pagination * {
      font-family: 'ikaros_sansregular', sans-serif;
      font-size: 28px !important; }
    .page ul.pagination a {
      display: inline-block;
      font-family: 'ikaros_sansregular', serif;
      text-decoration: none;
      transition: background-color 0.5s ease, color 0.5s ease; }
    .page ul.pagination li {
      display: inline; }
      .page ul.pagination li.active > span {
        text-decoration: underline !important; }
      .page ul.pagination li.disabled > span {
        color: #191919; }
    .page ul.pagination span {
      display: inline-block;
      vertical-align: text-top; }
    .page ul.pagination a, .page ul.pagination span {
      padding: 1em;
      margin: 0; }

.membres section .logo img {
  float: right;
  max-height: 150px;
  max-width: 150px;
  padding: 20px; }

.membres section .chapeau {
  font-style: italic; }

.partenaires ul {
  list-style-image: url("../img/bullet-orange.png"); }

.label table {
  margin-bottom: 40px; }

.label tr:hover {
  background: #DD6754; }

.label td a {
  display: block;
  border: 1px solid black;
  padding: 16px; }

.label ul {
  width: 50%; }

.chiffres h2 a {
  font-family: 'ikaros_sansregular', sans-serif;
  font-size: 19px;
  vertical-align: baseline; }
  .chiffres h2 a:hover {
    text-decoration: underline; }

.chiffres table {
  border: 2px solid black;
  margin-bottom: 3em; }
  .chiffres table thead td {
    border: none; }
  .chiffres table thead td:not(:first-child) a {
    font-family: 'ikaros_sansregular', sans-serif;
    font-weight: bold;
    text-transform: uppercase; }
    .chiffres table thead td:not(:first-child) a:hover {
      text-decoration: underline; }

.chiffres h2 {
  margin: 0 auto;
  padding: 0.82em 0 0 0; }

.chiffres table.donnees td {
  width: 25%; }

.chiffres tbody td:first-child {
  background-color: white;
  color: black;
  font-family: 'Merriweather', serif;
  padding: 0.1em;
  text-align: left; }

.chiffres tbody td:not(:first-child) {
  background-color: #DD6754;
  color: black;
  font-family: "Courier New", Courier, monospace !important;
  font-weight: bold;
  text-align: center; }

.chiffres table.bicolumn tbody td {
  width: 50%; }

.chiffres .explore-stats {
  border-top: 2px solid black;
  overflow: hidden; }
  .chiffres .explore-stats iframe {
    border: none;
    border-bottom: 2px solid black;
    height: 2000px;
    position: relative;
    top: -175px;
    width: 100%; }

.publications table {
  border: none; }

.publications table tbody td {
  border: solid 1px #ccc;
  padding: 0.25em; }
  .publications table tbody td:nth-child(2) {
    text-align: left; }
  .publications table tbody td a {
    color: #DD6754;
    text-decoration: none; }
    .publications table tbody td a:hover {
      text-decoration: underline; }

/*! http://responsiveslides.com v1.54 by @viljamis */
.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 350px;
  padding: 0;
  margin: 0; }
  .rslides li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    max-height: 350px;
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  .rslides img {
    display: block;
    width: 100%;
    height: auto;
    float: left;
    border: 0; }

.centered-btns_nav {
  z-index: 3;
  position: absolute;
  -webkit-tap-highlight-color: transparent;
  top: 55%;
  left: 0;
  opacity: 0.33;
  text-indent: -9999px;
  overflow: hidden;
  text-decoration: none;
  height: 51px;
  width: 38px;
  background: transparent url("../img/themes.gif") no-repeat left center;
  margin-top: -45px; }

.centered-btns_nav:active {
  opacity: 1.0; }

.centered-btns_nav.next {
  left: auto;
  right: 0;
  background-position: right center; }

.transparent-btns_nav {
  z-index: 3;
  position: absolute;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  left: 0;
  display: block;
  background: #fff;
  /* Fix for IE6-9 */
  opacity: 0;
  filter: alpha(opacity=1);
  width: 48%;
  text-indent: -9999px;
  overflow: hidden;
  height: 91%; }

.transparent-btns_nav.next {
  left: auto;
  right: 0; }

.large-btns_nav {
  z-index: 3;
  position: absolute;
  -webkit-tap-highlight-color: transparent;
  opacity: 0.4;
  text-indent: -9999px;
  overflow: hidden;
  top: 0;
  left: 0;
  background: #000 url("../img/themes.gif") no-repeat left 50%;
  width: 38px;
  height: 350px; }
  @media screen and (max-width: 1000px) {
    .large-btns_nav {
      top: 0;
      left: 0; } }

.large-btns_nav:active {
  opacity: 1.0; }

@media screen and (max-width: 1000px) {
  .large-btns_nav.prev {
    left: 0px;
    top: 0px;
    right: auto; } }

.large-btns_nav.next {
  left: auto;
  right: 0;
  background-position: right 50%; }
  @media screen and (max-width: 1000px) {
    .large-btns_nav.next {
      left: auto;
      top: 0px;
      right: 0px; } }

.centered-btns_nav:focus, .transparent-btns_nav:focus, .large-btns_nav:focus {
  outline: none; }

.centered-btns_tabs, .transparent-btns_tabs, .large-btns_tabs {
  z-index: 3;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0; }

.centered-btns_tabs li, .transparent-btns_tabs li, .large-btns_tabs li {
  display: inline;
  float: none;
  _float: left;
  *float: left;
  margin-right: 5px; }

.centered-btns_tabs a, .transparent-btns_tabs a, .large-btns_tabs a {
  text-indent: -9999px;
  overflow: hidden;
  border-radius: 15px;
  background: #ccc;
  background: rgba(0, 0, 0, 0.2);
  display: inline-block;
  _display: block;
  *display: block;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.3);
  width: 9px;
  height: 9px; }

.centered-btns_here a, .transparent-btns_here a, .large-btns_here a {
  background: #222;
  background: rgba(0, 0, 0, 0.8); }

/*# sourceMappingURL=erecolnat.css.map */
